import React from 'react';

import withLightbox from './settings';

import { BorderRadius } from '../../utils/variables';

import loadLibrary from '../Loadable/library';
import loadComponents from '../Loadable';

const Img = loadLibrary('gatsby-image');

const LightboxWrapper = loadComponents('lightbox-wrapper');

export default withLightbox(function ImageLightbox({
  thumb,
  image,
  alt,
  showLightbox,
  openLightbox,
  closeLightbox,
}) {
  return (
    <>
      <button type="button" className="hidden" onClick={e => openLightbox(e)}>
        <div style={imageStyle}>
          <Img image={thumb} alt={alt} />
        </div>
      </button>

      <LightboxWrapper
        showLightbox={showLightbox}
        closeLightbox={closeLightbox}
      >
        <div style={imageStyle}>
          <Img image={image} alt={alt} />
        </div>
      </LightboxWrapper>
    </>
  );
});

const imageStyle = {
  borderRadius: BorderRadius,
  overflow: `hidden`,
};
